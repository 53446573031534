import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffCodeSearchPanel from "./Left/TariffCodeSearchPanel";
import TariffCodeTablePanel from "./Table/TariffCodeTablePanel";
import TariffCodeTitleBar from "./TariffCodeTitleBar";

const TariffCodeMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const {searchCriteria,isShowCriteriaPanel,isBackFromDetail} = tariffCodeState;
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    tariffCodeVM.searchTariffCode({}),
                    tariffCodeVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(() => { 
            setIsLoading(false);
        }).catch(error => {            
            setIsLoading(false);
        });        
    }, [isBackFromDetail, searchCriteria, tariffCodeVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
           <TariffCodeTitleBar/>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<TariffCodeTablePanel/>}
                rightChildren={<TariffCodeSearchPanel/>} 
                />
        </div>
    </>
}

export default memo(TariffCodeMaintenance);