import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffCodeEditPanelTitleBar: React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const messageBarVM = useMessageBarVM();
    const {currentSelectedRow, masterState, componentState} = tariffCodeState;
    const {editingTariffCodeEntity, isAdd, isEditable, isRead} = masterState;
    
    const {isAdd: isCompAdd, isEditable: isCompEdit} = componentState;
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate, allowUpdate} = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead? currentSelectedRow : editingTariffCodeEntity;

    
    const isDisable = () => {
        if (isCompAdd || (!isCompAdd && isCompEdit)) return true;

        return false;
    }

    const handleReset = useCallback(async () => {
        tariffCodeVM.onResetClick();
    }, [tariffCodeVM]);

    const handleEdit = useCallback(()=>{
        tariffCodeVM.onEdit(currentEntity);
    },[currentEntity, tariffCodeVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            tariffCodeVM.onSaveClicked();
            const res = await tariffCodeVM.onSave(currentEntity,isAdd);
            if(res && res["mandatoryCheckFail"]){
                messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
            }else if(res){
                messageBarVM.showError(res);
            }
        } catch (error) {

        }finally{
            setIsLoading(false)
        }
        
    },[currentEntity, isAdd, messageBarVM, tariffCodeVM]);

    return <Sidebarheader style={{width: '100%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{title: TariffCodeConstant.Tariff.TARIFF},{ title: TariffCodeConstant.Title.TARIFF_CODE_MAIN }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={tariffCodeVM.onCloseClick} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={tariffCodeVM.onCloseClick} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(TariffCodeEditPanelTitleBar);