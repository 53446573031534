import _ from "lodash"
import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant"
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM"
import { useMessageBarVM } from "presentation/hook/useMessageBar"
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider"
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer"
import { NbisCommonField } from "presentation/view/components/NbisCommonField"
import { useCallback, useMemo, useState } from "react"
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core"
import { SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled"

export const TariffCodeComponentPanel = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const messageBarVM = useMessageBarVM();
    const { componentState, dynamicOptions} = tariffCodeState;
    const { tariffCodeComponentList, isAdd, isEditable, isRead, isSaveClicked, allFormState} = componentState;
    const currentTarCodeComp = componentState.currentSelectItem;
    const TARIFF_CODE_CONSTANT = TariffCodeConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    

    const tariffComponentValueDropdownOptions = useMemo(() => {  
        return currentTarCodeComp?.tariffCompCode  
            ? dynamicOptions.tariffComponentValueDropdownOptions[currentTarCodeComp.tariffCompCode]  
            : [];  
    }, [currentTarCodeComp?.tariffCompCode, dynamicOptions.tariffComponentValueDropdownOptions]);

    // useEffect(()=>{
    //     tariffCodeVM.onComponentChanged(currentTarCodeComp.tariffCompCode);
    // },[currentTarCodeComp.tariffCompCode, tariffCodeVM])


    const handleCancelClick = () => {
        tariffCodeVM.onComponentCancel();
    }

    const handleSaveClick = useCallback(async ()  => {
        if(!_.isEmpty(tariffCodeComponentList) && tariffCodeComponentList.find(e=> e.tempKey !==currentTarCodeComp.tempKey && e.tariffCompCode===currentTarCodeComp.tariffCompCode)){
            messageBarVM.showWarining('Tariff Component already exists.');
            return;
        }

        setIsLoading(true);
        tariffCodeVM.onComponentSaveClicked();
        const res = await tariffCodeVM.onTempSaveDetail(currentTarCodeComp);
        setIsLoading(false);
        if(res){
            messageBarVM.showWarining(res["mandatoryCheckFail"]);
        }
    },[currentTarCodeComp, messageBarVM, tariffCodeComponentList, tariffCodeVM])

    const memoTarComp = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"330px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentTarCodeComp?.tariffCompCode || ''}
                fieldValue={currentTarCodeComp?.tariffCompCode}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_COMP}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCompCode'}
                options={dynamicOptions.tariffComponentDropdownOptions}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffCodeVM.onComponentFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [TARIFF_CODE_CONSTANT.TARIFF_COMP, allFormState, currentTarCodeComp?.tariffCompCode, dynamicOptions.tariffComponentDropdownOptions, isAdd, isSaveClicked, tariffCodeVM])

    const memoTarCompValue = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"330px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentTarCodeComp?.tariffCompValueCode || ''}
                fieldValue={currentTarCodeComp?.tariffCompValueCode}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_COMP_VAL}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCompValueCode'}
                options={tariffComponentValueDropdownOptions}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffCodeVM.onComponentFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [TARIFF_CODE_CONSTANT.TARIFF_COMP_VAL, allFormState, currentTarCodeComp?.tariffCompValueCode, isRead, isSaveClicked, tariffCodeVM, tariffComponentValueDropdownOptions])

    return (
        <div className='side-form-content-wrapper' style={{position:'relative', height:"100vh-128px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            <SidebarTitle>{isAdd?'NEW TARIFF COMPONENT': currentTarCodeComp.tariffCompCode + ' - ' + currentTarCodeComp.tariffCompValueCode}</SidebarTitle>
                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
           
            <div className={'add-edit-form'} style={{ height:'50vh', maxHeight:'50vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoTarComp}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoTarCompValue}
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead?'Close':'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            {!isRead&&<HPHButton label={isAdd?'Add':"Update"} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

