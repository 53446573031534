import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import TariffCodeSearchForm from "./TariffCodeSearchForm";

const TariffCodeSearchPanel = () => {
    const TARIFF_CODE_CONSTANT = TariffCodeConstant.Search;
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const {searchCriteria} = tariffCodeState;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        tariffCodeVM.onSearchCriteriaResetClick();
    }, [tariffCodeVM]);
    
    const searchButtonClicked = useCallback(() => {

        setIsLoading(true);
        tariffCodeVM.searchTariffCode(searchCriteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }, [searchCriteria, tariffCodeVM])

    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{TARIFF_CODE_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={tariffCodeVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <TariffCodeSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked()} />
            </SidebarActionBar>
        </div>
    );
}

export default TariffCodeSearchPanel;
