import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { TariffCodeHeaderFormPanel } from "./Form/TariffCodeHeaderFormPanel";
import TariffCodeEditPanel from "./TariffCodeEditPanel";
import TariffCodeMaintenance from "./TariffCodeMaintenance";

export const TariffCodeMasterView: React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const { isAdd, isEditable, isSliderOpen } = tariffCodeState.masterState;

    return <>
        <div className="main-comp-wrapper">
            {/* Tariff code table panel */}
            <TariffCodeMaintenance/>
            {/* Tariff code editable panel */}
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"36%"}
                    rightSectionWidth={"64%"}
                    leftChildren={<TariffCodeHeaderFormPanel/>}
                    rightChildren={<TariffCodeEditPanel/>}
                />
                )
            }
        </div>
    </>
}