import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TariffCodeProvider } from "presentation/store/TariffCode/TariffCodeProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { TariffCodeMasterView } from "presentation/view/section/TariffCode/TariffCodeMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TariffCodeContMain = () => {
    return <ANAInfoWrapper permission={Permission.TARIFF_CODE_MAINTENANCE}>
        <MessageBarWrapper>
            <TariffCodeProvider>
                <GridStyles/>
                <TariffCodeMasterView/>
            </TariffCodeProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TariffCodeContMain;