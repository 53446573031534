
export interface TariffCodeSearchCriteria{
    tariffTypeList?: string[] | null,
    tariffCode?: string | null,
    
    parentTariffType?: string | null,
    parentTariffCode?: string | null,
    
    tariffCodeDesc?: string | null,    
    tariffCodeOtherDesc?: string | null,

    activeInd?: 'Y'|'N',
    createdDateFrom?: Date | null,
    createdDateTo?: Date | null,  
    
    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_TARIFF_CODE_SEARCH_CRITERIA : TariffCodeSearchCriteria = {

    tariffTypeList: [],
    tariffCode: null,
    parentTariffType: null,
    parentTariffCode: null,
    tariffCodeDesc: null,
    tariffCodeOtherDesc: null,
    createdDateFrom: null,   
    createdDateTo: null,   
}