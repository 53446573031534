import { EMPTY_TARIFF_CODE_SEARCH_CRITERIA } from "domain/entity/TariffCode/TariffCodeSearchCriteria";
import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useMemo } from "react";
import { InputDropdown } from "veronica-ui-component/dist/component/core";

const TariffCodeSearchForm = () => {    
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const searchCriteria = tariffCodeState.searchCriteria;

    

    const memoTarType = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={TariffCodeConstant.Table.TARIFF_TYPE}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.tariffTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => {                    
                    tariffCodeVM.onMultipleDropdownChange(e, 'tariffTypeList')
                    tariffCodeVM.onDropdownChange(EMPTY_TARIFF_CODE_SEARCH_CRITERIA.tariffCode, 'tariffCodeList')
                }}
                options={tariffCodeState.dynamicOptions.tariffTypeDropdownOptions}/>
        </div>
    , [searchCriteria.tariffTypeList, tariffCodeState.dynamicOptions.tariffTypeDropdownOptions, tariffCodeVM])
    
    return <>        
        
        <CriteriaItemContainer>
            {memoTarType}
        </CriteriaItemContainer>
    </>;
}
export default memo(TariffCodeSearchForm);