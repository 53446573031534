
import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import FileUpload from "presentation/view/components/FileUpload";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffCodeTitleBar:React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const messageBarVM = useMessageBarVM();    
    // const {updatedRows} = tariffCodeState;
    const TARIFF_CODE_CONSTANT = TariffCodeConstant.Title;
    const [ isLoading,setLoading ] = useState<boolean>(false);

    const handleAdd = useCallback(() => {
        tariffCodeVM.onAddClick();
    }, [tariffCodeVM]);

    // const handleApply = useCallback(async () => {
    //     if(_.isEmpty(updatedRows)) return;
    //     setLoading(true);
    //     const res = await tariffCodeVM.onApply(updatedRows);
    //     setLoading(false);
    //     if(!!!res || !res.success){
    //         messageBarVM.showWarining('Apply failed.');
    //     }else{
    //         await tariffCodeVM.searchTariffCode({});
    //     }
    //     setLoading(false);
    // }, [messageBarVM, tariffCodeVM, updatedRows]);

    const handleUpload = useCallback(async (e:any) => {

        const files = e.target?.files;
        const file = files[0];

        if(!file){
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showWarining('Please upload a .csv file');
            return
        }
        
        setLoading(true);
        const res = await tariffCodeVM.onUpload(file);
        
        e.target = null;

        if(res.success){
            messageBarVM.showSuccess("Success!");
        }else{
            messageBarVM.showError(res.data);
        }

        setLoading(false);


        tariffCodeVM.searchTariffCode({});

    }, [messageBarVM, tariffCodeVM]);


    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{TARIFF_CODE_CONSTANT.TARIFF_CODE_MAIN}</HeaderTitle>
        {(tariffCodeState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
       
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} /> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={WorkspaceConstant.Common.BUTTON_ADD} onClick={handleAdd}/>
            <div className="add-seperator"/>
            <FileUpload handleUpload={handleUpload} label={WorkspaceConstant.Common.BUTTON_UPLOAD} theme={"Primary"} accept=".csv" size={"Small"} disabled={false}/>
            {/* <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={false} onClick={handleApply} /> */}
        </StyledAction>
    </Sidebarheader>
}

export default memo(TariffCodeTitleBar);