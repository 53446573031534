import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { TariffCodeConstant } from "./TariffCodeConstant";

const TAR_CODE_MAIN_CONSTANT = TariffCodeConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_TARIFF_CODE_COMP_COL_DEF: any[] = [
    {
        headerName: TAR_CODE_MAIN_CONSTANT.SEQ,
        field: "seq",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,

    },
    {
        headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_COMP,
        field: "tariffCompCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 190,
    },
    {
        headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_COMP_DESC,
        field: "tariffCompDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_COMP_VAL,
        field: "tariffCompValueCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: TAR_CODE_MAIN_CONSTANT.TARIFF_COMP_VAL_DESC,
        field: "tariffCompValueDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 260,
    },


].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
