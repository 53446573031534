import { SelectionChangedEvent } from "ag-grid-community";
import { StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import _ from "lodash";
import { INITIAL_TARIFF_CODE_COL_DEF } from "presentation/constant/TariffCode/TariffCodeColumnDefinition";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { changeCursor } from "presentation/view/components/NbisRightClickMenu/RightClickMenuAssist";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const TariffCodeTablePanel: React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const messageBarVM = useMessageBarVM();

    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<StandardTariffCodeEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return tariffCodeState.selectedRows;
    }, [tariffCodeState.selectedRows]);

    const getShowMoveCursor = useCallback(() => {
        return showMoveCursor;
    }, [showMoveCursor]);
    //for right click menu with move after end

    useEffect(() => {
        const columnDefs = (INITIAL_TARIFF_CODE_COL_DEF.slice());

        if (!tariffCodeState.selectedRows || tariffCodeState.selectedRows.length <= 0) {
            //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
        changeCursor("tariff-code-table", getShowMoveCursor());
    });

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        tariffCodeVM.updateSelectedRows(selectedRows);
    }, [tariffCodeVM]);

    useEffect(() => {
        if (!onTableSelectionClicked) return;
        gridRef?.current?.gridRef.current.api?.deselectAll();
        changeCursor("tariff-code-table", getShowMoveCursor());
    }, [getShowMoveCursor, onTableSelectionClicked]);

    const handleRowDoubleClick = useCallback((entity: StandardTariffCodeEntity) => {
        tariffCodeVM.onRowDoubleClick(entity);
    }, [tariffCodeVM]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(tariffCodeState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, tariffCodeState.selectedRows]);

    // const handleRowDrag = useCallback((e: any, updatedRows: StandardTariffCodeEntity[], movedIndex: number, overIndex: number) => {
    //     tariffCodeVM.onRowDrag(updatedRows);
    //     changeCursor("tariff-code-table", getShowMoveCursor());
    // }, [tariffCodeVM, getShowMoveCursor]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        tariffCodeVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [tariffCodeVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(tariffCodeState.tariffCodeEntityList);
        tariffCodeVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [tariffCodeState.tariffCodeEntityList, tariffCodeVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        tariffCodeVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showWarining('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                tariffCodeVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                tariffCodeVM.searchTariffCode({}).then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    tariffCodeVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    tariffCodeVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, tariffCodeVM]);

    const memoTariffCodeTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="seq" />}
                <div id="myDiv">
                    <NbisTable
                        id='tariff-code-table'
                        isNewColumnSetting={true}
                        columns={INITIAL_TARIFF_CODE_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={showMoveCursor ? false : true}
                        showCutCursor={showMoveCursor}
                        isRowHighligted={true}
                        // rowSelection={showMoveCursor ? "single" : "multiple"}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: StandardTariffCodeEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 125px)"
                        ref={gridRef}
                        onBodyScroll={(e: any) => changeCursor("tariff-code-table", getShowMoveCursor())}
                    />
                </div>
            </>
        );
    }, [allRows, allowUpdate, getAllRows, getSelectedRows, getShowMoveCursor, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor]);

    useEffect(() => {
        if (initialAllRows && tariffCodeState.tariffCodeEntityList && !_.isEmpty(tariffCodeState.tariffCodeEntityList)) {
            setAllRows(tariffCodeState.tariffCodeEntityList.map((tariffCodeEntity, index) => ({
                ...tariffCodeEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, tariffCodeState.tariffCodeEntityList]);
    // for right click menu with move after end

    return (
        <>
            <TableWrapper>
                {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}

                {memoTariffCodeTable}
            </TableWrapper>
        </>
    );
};

export default memo(TariffCodeTablePanel);