import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { TariffComponentEntity } from "domain/entity/TariffCode/TariffComponentEntity";
import _ from "lodash";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { TariffComponentRepository } from "./TariffComponentRepo";

export const TariffComponentRepoImpl = (): TariffComponentRepository => {
    const url = '/v1/tariffComponent';
    const urlActive = '/v1/tariffComponentActive';

    const getAllActiveTariffComponents = async() : Promise<TariffComponentEntity[]> => {
        return axiosGetData(tariffAxiosInstance, `${urlActive}`, []).then(res => {
            return _.sortBy(res.data, ["seq"]);
        }).catch(err => {
            return [];
        });
    }

    const getEntities = async() : Promise<TariffComponentEntity[]> => {
        return await axiosGetData(tariffAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
        
    }

    const getEntityByKey = async(key: string): Promise<TariffComponentEntity> => {
        return await axiosGetData(tariffAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createEntity = async(newData: TariffComponentEntity) : Promise<ResponseEntity> => {
        return await axiosPostData(tariffAxiosInstance, `${url}`, newData);
    }

    const updateEntity = async(updatedData: TariffComponentEntity): Promise<ResponseEntity> => {
        return await axiosPutData(tariffAxiosInstance, `${url}`, updatedData);
    }

    const deleteEntity= async(key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(tariffAxiosInstance, `${url}`, key);
    }

    const onApply = async(updatedRows: TariffComponentEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/tariffComponentApply"
        return await axiosPostData(tariffAxiosInstance, `${applyUrl}`, updatedRows);
    }


    return {
        getAllActiveTariffComponents: getAllActiveTariffComponents,
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
        onApply: onApply,
    }
}