import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { EMPTY_STANDARD_TARIFF_CODE_ENTITY, StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import { EMPTY_TARIFF_CODE_COMP_ENTITY, TariffCodeComponentEntity } from "domain/entity/TariffCode/TariffCodeComponentEntity";
import { EMPTY_TARIFF_CODE_SEARCH_CRITERIA, TariffCodeSearchCriteria } from "domain/entity/TariffCode/TariffCodeSearchCriteria";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffCodeComponentRepository } from "domain/repository/TariffCode/TariffCodeComponentRepo";
import { TariffComponentRepository } from "domain/repository/TariffCode/TariffComponentRepo";
import { TariffComponentValueRepository } from "domain/repository/TariffCode/TariffComponentValueRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { tariffCodeCSVColumnMapping, tariffCodePositiveIntegerNumberFieldList } from "presentation/constant/TariffCode/TariffCodeConstant";
import { createTariffCodeComponentValidationSchema, createTariffCodeValidationSchema, updateTariffCodeValidationSchema } from "presentation/constant/TariffCode/TariffCodeValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { TariffCodeModel } from "presentation/model/TariffCode/TariffCodeModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface TariffCodeVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TariffCodeModel>> | ((value: SetStateAction<TariffCodeModel>) => void),
    ],
    tariffTypeRepo: TariffTypeRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    tariffComponentRepo: TariffComponentRepository,
    tariffComponentValueRepo: TariffComponentValueRepository,
    tariffCodeComponentRepo: TariffCodeComponentRepository,
    masterDataRepo: MasterDataRepository,
}

export const TariffCodeVM = ({dispatch,tariffTypeRepo,standardTariffCodeRepo,tariffComponentRepo, tariffComponentValueRepo,tariffCodeComponentRepo, masterDataRepo}:TariffCodeVMProps) => {
    const [tariffCodeDispatch] = dispatch;
    
    const onShowLoading = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (rows:StandardTariffCodeEntity[]) => {

        tariffCodeDispatch(prevState => {             
            return {
                ...prevState,
                selectedRows: rows,
            }
        })
    }

    const onRowDoubleClick = (entity: StandardTariffCodeEntity) => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: {
                    ...entity,
                    tariffNatureList: !!!entity?.tarNature ? [] : entity.tarNature.split(",")?.map(e => e.trim())
                },   
                masterState:{
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
                componentState:{                    
                    ...prevState.componentState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }

    const onRowDrag = (rows: StandardTariffCodeEntity[]) => {
        tariffCodeDispatch(prevState =>{ 
            const prevRows = prevState.tariffCodeEntityList;
            const toChangedRows = rows.map((e, index) => ({...e,tempSeq: prevRows[index].seq})).filter(e=>e.tempSeq!==e.seq);            
            return ({
                ...prevState,
                tariffCodeEntityList: rows,
                updatedRows: toChangedRows,
        })})
    }
    
    const loadDropdownOption = async () => {
        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }
                })            

                tariffCodeDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        parentTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await tariffComponentRepo.getAllActiveTariffComponents().then(
            tarComps => {

                let tariffComponentDropdownOptions:DropdownProps[] = [];
                let tariffComponentValueDropdownOptions:{[key:string]: DropdownProps[]} = {};

                tarComps.forEach(comp =>{
                    tariffComponentDropdownOptions.push({
                        dropdownLabel: comp.tariffCompCode,
                        tagLabel: comp.tariffCompCode,
                        value: comp.tariffCompCode,
                    })

                    let tarCompValDropdownOptions = comp.componentValues?.map((compVal) => ({
                        dropdownLabel: compVal.tariffCompValueCode,
                        tagLabel: compVal.tariffCompValueCode,
                        value: compVal.tariffCompValueCode,
                    })) ?? [];

                    tariffComponentValueDropdownOptions[comp.tariffCompCode] = tarCompValDropdownOptions;

                })

                tariffCodeDispatch(prevState =>({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffComponentDropdownOptions: _.sortBy(tariffComponentDropdownOptions,'dropdownLabel'),
                        tariffComponentValueDropdownOptions: tariffComponentValueDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.TAR_NATURE).then(
            tariffNatures => {
                let tariffNatureDropdownOptions = tariffNatures?.map((entity) => ({
                    dropdownLabel: entity.code,
                    tagLabel: entity.code,
                    value: entity.code,
                })) ?? []

                tariffCodeDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffNatureDropdownOptions: tariffNatureDropdownOptions,
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getTariffScheme().then(
            tarSchemes => {

               const  tariffSchemeDropdownOptions = tarSchemes?.map((scheme) => ({
                    dropdownLabel: scheme.tariffSchemeCode,
                    tagLabel: scheme.tariffSchemeCode,
                    value: scheme.tariffSchemeCode,
                })) ?? [];

                tariffCodeDispatch(prevState =>({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffSchemeDropdownOptions: tariffSchemeDropdownOptions
                    }
                }))
            }
        )
    }

    const onSearchCriteriaResetClick = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: EMPTY_TARIFF_CODE_SEARCH_CRITERIA
            }
        })
    }

    const onSearchClick = async() => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }



    const searchTariffCode = async(searchCriteria: TariffCodeSearchCriteria) => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isBackFromDetail:false,
                selectedRows: [],
                tariffCodeEntityList: [],
                currentSelectedRow: {...EMPTY_STANDARD_TARIFF_CODE_ENTITY}
            }
        })
        await standardTariffCodeRepo.findAllActiveUnActiveStandardTariffCodes().then((data) => {
            tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    tariffCodeEntityList: data,
                    selectedRows: [],
                }
            })
        }).catch((error) => {
            return [];
        })

    }

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {  
        
        const val =  e.target.value;
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === '' ? val.toUpperCase().replace(/\s+/g, '') : val,
            }
        }))
    };


    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));
    };

    const onDropdownChange = (e: any, fieldName: string) => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    (fieldName === "tariffCodeList" ? [] : '') : e),
            }
        }))
    }


    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }


    const onAddClick = () => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            //isShowDetail: true,
            currentSelectedRow: EMPTY_STANDARD_TARIFF_CODE_ENTITY,
            masterState:{
                ...prevState.masterState,
                isTabularDataActive: false,
                isAdd: true,
                isRead: false,
                isEditable: false,
                isSaveClicked: false,
                isSliderOpen: true,
                allFormState: {},
                editingTariffCodeEntity: EMPTY_STANDARD_TARIFF_CODE_ENTITY,
            },
            componentState:{                    
                ...prevState.componentState,
                isTabularDataActive: false,
                isAdd: false,
                isRead: true,
                isEditable: false,
                isSliderOpen: false,
                isSelected: false,
                allFormState: {}
            }
        }))
    };

    const onHeaderSingleDropdownChange = (e: any, fieldName: string) => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingTariffCodeEntity: {
                        ...prevState.masterState.editingTariffCodeEntity,
                        [fieldName]: e.value,
                    }
                }
            }
        });
    };

    const onHeaderMultipleDropdownChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        tariffCodeDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingTariffCodeEntity: {
                    ...prevState.masterState.editingTariffCodeEntity,
                    [fieldKey]: val,
                },
                allFormState:{
                    ...prevState.masterState.allFormState,
                    [fieldKey]: '',
                }
            }
        }));
    };

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        tariffCodeDispatch(prevState => {

            if(tariffCodePositiveIntegerNumberFieldList.includes(fieldKey)){
                var posIntReg = /^\d*$/;
                if (!posIntReg.test(val)) { 
                    val = prevState.masterState.editingTariffCodeEntity[fieldKey] ;
                }
            }

            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingTariffCodeEntity: {
                        ...prevState.masterState.editingTariffCodeEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onUpload = async (file: File) => {
        
        const arrayBuffer = await readFileAsArrayBuffer(file);
        const tariffCodeEntities = await processFileData(arrayBuffer);

        return await standardTariffCodeRepo.uploadData(tariffCodeEntities);
    }

    const readFileAsArrayBuffer = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => resolve(e.target?.result);
            reader.onerror = (e) => reject(e.target?.error);

            reader.readAsArrayBuffer(file);
        });
    }


    const processFileData = async (arrayBuffer: any) : Promise<StandardTariffCodeEntity [] > => {
        const byteArray = new Uint8Array(arrayBuffer);        
        const decoder = new TextDecoder('ascii');
        const content = decoder.decode(byteArray);
        const lines = content.split('\n').map(line => line.trim());

        const result: StandardTariffCodeEntity [] = [];
        
        lines.forEach(line => {  
            const obj: StandardTariffCodeEntity  = {...EMPTY_STANDARD_TARIFF_CODE_ENTITY};
            const lineObjArr = line.split(',');
            for (let i = 0; i < tariffCodeCSVColumnMapping.length && i < lineObjArr.length; i++) {  
                obj[tariffCodeCSVColumnMapping[i]] = _.isEmpty(lineObjArr[i]) ? null : lineObjArr[i];  
            }
            result.push(obj);
        }); 

        return result;
    }

    const onApply = async (updatedRows: StandardTariffCodeEntity[]) => {


        return await standardTariffCodeRepo.onApply(updatedRows);
    }

    const onResetClick = async () => {
        tariffCodeDispatch(prevState => {
            let tempTarCodeEty: StandardTariffCodeEntity = EMPTY_STANDARD_TARIFF_CODE_ENTITY;
            if(!prevState.masterState.isAdd){
                tempTarCodeEty = prevState.currentSelectedRow
            }

            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingTariffCodeEntity: {
                        ...tempTarCodeEty,
                    },
                }
            }
        })
    }

    const onTempSaveDetail = async (currentTarCodeComp: TariffCodeComponentEntity) => {
        const valResult = await Validation(createTariffCodeComponentValidationSchema).ValidateFormOnly(currentTarCodeComp);
        const compCode = currentTarCodeComp.tariffCompCode;
        const compValCode = currentTarCodeComp.tariffCompValueCode;

        let msg = 'Error when save data.'
        
        let validatedResult: {[x: string]: string} = {};  
        if (valResult) {
            
            validatedResult = { ...validatedResult, ...valResult, mandatoryCheckFail: 'Please input the missing value.' }; 



            tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        allFormState: {
                            ...validatedResult
                        },
                    }                        
                }
            });
            return validatedResult;
        } else {

            // check illegality
            const compValEtyList = await tariffComponentValueRepo.getTariffComponentValues({
                searchCriteria: {
                    tariffCompCode: compCode,
                    tariffCompValueCode: compValCode,
                }
            })

            if(_.isEmpty(compValEtyList)){
                msg = 'Invalid Tariff Component Code & Value.';

                return {...validatedResult, mandatoryCheckFail: msg};
            }else{
                return tariffCodeDispatch(prevState => {
                    const tarCompVal = compValEtyList[0];
                    const prevTarCodeCompList = prevState.componentState.tariffCodeComponentList;
                    let data = null;

                    let tarCodeCompEntity: TariffCodeComponentEntity = {
                        ...EMPTY_TARIFF_CODE_COMP_ENTITY,
                        seq: tarCompVal.seq,
                        tariffCompId: tarCompVal.tariffCompId??0,
                        tariffCompCode: tarCompVal.tariffCompCode,
                        tariffCompDesc: tarCompVal.tariffCompDesc,
                        tariffCompValueId: tarCompVal.id??0,
                        tariffCompValueCode: tarCompVal.tariffCompValueCode,
                        tariffCompValueDesc: tarCompVal.tariffCompValueDesc,
                    }

                    if(_.isEmpty(prevTarCodeCompList)){
                        data = Array.of(tarCodeCompEntity)
                    }else{
                        const filteredList = prevTarCodeCompList.filter(val => val.tariffCompCode !== tarCompVal.tariffCompCode 
                            && val.tariffCompValueCode !== tarCompVal.tariffCompValueCode)??[];
                        filteredList.push(tarCodeCompEntity);

                        data = _.sortBy(filteredList, ["seq"]);
                    }

                    return {
                        ...prevState,
                        masterState:{
                            ...prevState.masterState,
                            editingTariffCodeEntity:{
                                ...prevState.masterState.editingTariffCodeEntity,
                                tariffCode: "",
                                tariffCodeDesc: "",
                            }
                        },
                        componentState:{
                            ...prevState.componentState,
                            tariffCodeComponentList: data,
                            selectedRows: [],
                            currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
                            isShowDetailInfo: false,
                            isAdd: false,
                            isEditable: false,
                            isRead: true,
                            isSliderOpen: false,
                        }
                    }
                })
            }
        }
    }

    const onGenerate = () => {
        tariffCodeDispatch(prevState => {
            const tarCodeCompList = prevState.componentState.tariffCodeComponentList;
            let tariffCode = '';
            let tariffCodeDesc = '';
            let tariffCompValIdList: number[]= [];
            if(!_.isEmpty(tarCodeCompList)){
                tariffCode = tarCodeCompList?.map(e => e.tariffCompValueCode).join('');
                tariffCodeDesc = tarCodeCompList?.map(e => e.tariffCompValueDesc).join(' ');
                tariffCompValIdList = tarCodeCompList?.map(e => e.tariffCompValueId as number)??[];
            }

            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingTariffCodeEntity: {
                        ...prevState.masterState.editingTariffCodeEntity,
                        tariffCode: tariffCode,
                        tariffCodeDesc: tariffCodeDesc,
                        tariffCompValIdList: tariffCompValIdList,
                    },
                }
            }
        })
    }

    const onSave =  async(currentTarCodeEty: StandardTariffCodeEntity, isAdd: boolean) => {
        const valHdrResult = await Validation(isAdd?createTariffCodeValidationSchema:updateTariffCodeValidationSchema).ValidateFormOnly(currentTarCodeEty);
        
        if (valHdrResult ) {
            let validatedTariffResult: {[x: string]: string} = {};  
            if (valHdrResult) {  
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };  
            }  



            tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }                        
                }
            });
            return validatedTariffResult;
        } else {

            let res = null;
            if(isAdd){
                res = await standardTariffCodeRepo.createNewStandardTariffCode(currentTarCodeEty);
            }else{
                res = await standardTariffCodeRepo.updateStandardTariffCode(currentTarCodeEty);
            }
            
            if(res.success){    
                onCloseClick();
            }else{
                return res.data;
            }
        }
    }

    const onCloseClick = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail:true,
                selectedRows:[],
                currentSelectedRow: {...EMPTY_STANDARD_TARIFF_CODE_ENTITY},
                masterState:{
                    ...prevState.masterState,
                    isAdd:false,
                    isEditable:false,
                    isRead: true,
                    isTabularDataActive: true,
                    editingTariffCodeEntity: EMPTY_STANDARD_TARIFF_CODE_ENTITY,
                },
                componentState:{
                    ...prevState.componentState,
                    isAdd:false,
                    isEditable:false,
                    isRead: true,
                    isTabularDataActive: true,
                    tariffCodeComponentList:[],
                    currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
                    selectedRows:[]
                }
            }
        }); 
    }

    const onSaveClicked =() => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    
    const onEdit = (currentTarCodeEty: StandardTariffCodeEntity)=>{
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true, 
                    
                    editingTariffCodeEntity:{
                        ...currentTarCodeEty,
                    }
                },
            }
        })
    }

    const onRadioChange = (e: any, fieldName: string) => {
        if(e.checked){
            return tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    masterState:{
                        ...prevState.masterState,
                        editingTariffCodeEntity: {
                            ...prevState.masterState.editingTariffCodeEntity,
                            [fieldName]: e.key,
                        },
                    } 
                    }
                             
                })
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        tariffCodeDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingTariffCodeEntity : {
                    ...prevState.masterState.editingTariffCodeEntity,
                    [fieldName]: checked?"Y":"N",
                }
            }
        }))
    }

    const onComponentCancel = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,
                    currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    allFormState:{}
                }
            }
        })
    }

    const onComponentSaveClicked =() => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onComponentFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }


        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,
                    currentSelectItem: {
                        ...prevState.componentState.currentSelectItem,
                        [fieldKey]: val
                    },
                    allFormState:{
                        ...prevState.componentState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const updateSelectedComponentRows = async (rows: any[]) => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,
                    //currentSelectItem: rows[0],
                    selectedRows: rows,
                }
            }
        })
    }

    const onComponentRowClick = (row: TariffCodeComponentEntity) => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,
                    currentSelectItem: row,
                    isSliderOpen:true,
                    isRead: !prevState.masterState.isAdd, 
                    isEditable: prevState.masterState.isAdd,
                }                       
            }
        });
    }

    const onComponentAdd = () => {
        tariffCodeDispatch(prevState => {
            const compCodes = prevState.dynamicOptions.tariffComponentDropdownOptions;

            const defaultCompCode = _.isEmpty(compCodes)?'' : compCodes[0].value
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,  
                    currentSelectItem: {
                        ...EMPTY_TARIFF_CODE_COMP_ENTITY,
                        tempKey: _.uniqueId(),
                        tariffCompCode: defaultCompCode,
                        tariffCompValueCode: ''
                    },   
                    isAdd:true,
                    isSliderOpen: true,
                    isEditable:false,
                    isRead: false,
                    isSaveClicked: false,
                }
            }
        })
    }

    const onComponentDelete = (selectedRows: TariffCodeComponentEntity[]) => {
        tariffCodeDispatch(prevState => {
            const rows = prevState.componentState.tariffCodeComponentList;
            const retainRows = rows.filter(row => !selectedRows.find(delRow =>
                 row.tariffCompId===delRow.tariffCompId && row.tariffCompValueId===delRow.tariffCompValueId));


            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingTariffCodeEntity:{
                        ...prevState.masterState.editingTariffCodeEntity,
                        tariffCode: "",
                        tariffCodeDesc: "",
                    }
                },
                componentState:{
                    ...prevState.componentState, 
                    tariffCodeComponentList: retainRows??[]
                }
            }
        })
    }

    const onSearchComponent = async (tariffCodeId: number) => {

        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,
                    tariffCodeComponentList: [],
                    selectedRows: [],
                }
            }
        })

        tariffCodeComponentRepo.getTariffCodeComponentByTariffCodeId(tariffCodeId).then((data) => {
            tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    componentState:{
                        ...prevState.componentState,
                        tariffCodeComponentList: data,
                        selectedRows: [],
                        currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
                        editingTariffCodeEntity: EMPTY_STANDARD_TARIFF_CODE_ENTITY,
                    }
                }
            })
        })
    }

    const onComponentChanged = (componentCode: string | null) => {
        tariffCodeDispatch(prevState => {
            const valCode = !!!componentCode ? '' : prevState.dynamicOptions.tariffComponentValueDropdownOptions[componentCode][0].value;
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,
                    currentSelectItem: {
                        ...prevState.componentState.currentSelectItem,
                        tariffCompValueCode: valCode,
                    }
                }
            }
        })
    }

    return {
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onHeaderSingleDropdownChange: onHeaderSingleDropdownChange,
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,
        onRowDrag: onRowDrag,
        updateSelectedRows: updateSelectedRows,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onSearchClick: onSearchClick,
        searchTariffCode: searchTariffCode,
        onInputTextChange: onInputTextChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDropdownChange: onDropdownChange,
        onDateRangeChange: onDateRangeChange,
        onAddClick:onAddClick,
        onUpload: onUpload,
        onApply: onApply,
        onResetClick: onResetClick,
        onTempSaveDetail: onTempSaveDetail,
        onSave: onSave,
        onGenerate: onGenerate,
        onCloseClick: onCloseClick,
        onSaveClicked: onSaveClicked,
        onEdit: onEdit,
        onRadioChange: onRadioChange,
        onCheckboxChange: onCheckboxChange,
        onComponentCancel: onComponentCancel,
        onComponentSaveClicked: onComponentSaveClicked,
        onComponentFieldChange: onComponentFieldChange,
        updateSelectedComponentRows:updateSelectedComponentRows,
        onComponentRowClick: onComponentRowClick,
        onComponentAdd: onComponentAdd,
        onComponentDelete: onComponentDelete,
        onSearchComponent: onSearchComponent,
        onComponentChanged: onComponentChanged,
    }
}