import { EMPTY_STANDARD_TARIFF_CODE_ENTITY, StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import { EMPTY_TARIFF_CODE_SEARCH_CRITERIA, TariffCodeSearchCriteria } from "domain/entity/TariffCode/TariffCodeSearchCriteria";

import { EMPTY_TARIFF_CODE_COMP_ENTITY, TariffCodeComponentEntity } from "domain/entity/TariffCode/TariffCodeComponentEntity";
import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TariffCodeDropdownOptions {

    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    tariffNatureDropdownOptions: DropdownProps[],
    
    parentTariffTypeDropdownOptions: DropdownProps[],
    parentTariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    tariffComponentDropdownOptions: DropdownProps[],
    tariffComponentValueDropdownOptions: { [key: string]: DropdownProps[] },
    tariffSchemeDropdownOptions: DropdownProps[],

}
export interface TariffCodeChangeState extends BaseViewChangeSate {
    editingTariffCodeEntity: StandardTariffCodeEntity,
}

export interface TariffCodeComponentChangeState extends BaseViewChangeSate {
    tariffCodeComponentList: TariffCodeComponentEntity[],
    currentSelectItem: TariffCodeComponentEntity,
    selectedRows: TariffCodeComponentEntity[],
}

export interface TariffCodeModel {
    isLoading: boolean,
    isShowDetail: boolean,
    isShowCriteriaPanel: boolean,
    tariffCodeEntityList: StandardTariffCodeEntity[],
    currentSelectedRow: StandardTariffCodeEntity,
    selectedRows: StandardTariffCodeEntity[],
    searchCriteria: TariffCodeSearchCriteria,
    dynamicOptions: TariffCodeDropdownOptions,
    isBackFromDetail: boolean,
    subMenuItemArray: MenuItem[],
    isShowMoveToPanel: boolean,
    masterState: TariffCodeChangeState,
    componentState: TariffCodeComponentChangeState,
    updatedRows: StandardTariffCodeEntity[],
}

export const EMPTY_TARIFF_CODE_MODEL: TariffCodeModel = {
    isLoading: false,
    isShowDetail: false,
    isShowCriteriaPanel: false,
    tariffCodeEntityList: [],
    currentSelectedRow: { ...EMPTY_STANDARD_TARIFF_CODE_ENTITY },
    selectedRows: [],
    searchCriteria: { ...EMPTY_TARIFF_CODE_SEARCH_CRITERIA },
    dynamicOptions: {
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        tariffNatureDropdownOptions: [],
        parentTariffTypeDropdownOptions: [],
        parentTariffCodeDropdownOptions: {},
        tariffComponentDropdownOptions: [],
        tariffComponentValueDropdownOptions: {},
        tariffSchemeDropdownOptions: []
    },
    isBackFromDetail: false,
    subMenuItemArray: [],
    isShowMoveToPanel: false,
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingTariffCodeEntity: EMPTY_STANDARD_TARIFF_CODE_ENTITY
    },
    componentState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        tariffCodeComponentList: [],
        currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
        selectedRows: []
    },
    updatedRows: []
}