import { EMPTY_TARIFF_CODE_MODEL } from "presentation/model/TariffCode/TariffCodeModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: TariffCodeProvider,
    useTracked: useTariffCodeTracked
} = createContainer(() => useState(EMPTY_TARIFF_CODE_MODEL), {concurrentMode: true});
export { TariffCodeProvider, useTariffCodeTracked };

